body {
  font-family: 'Google Sans', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

.background {
  position: fixed;
  background-size: cover;
  background: white;

}

.background-white {
  position: fixed;
  background-size: cover;
  background: white;
}

.background-black {
  position: fixed;
  background-size: cover;
  background-color: black;
}

.panel {
  border-radius: 20px;
  box-shadow: 0 10px white;
  text-align: center;
  background-color: white;
}

.logo-container {
  position: absolute;
  top: -15%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 25%;
  min-width: 300px;
  height: 30%;
  z-index: 100;
}

.logo {
  width: 60%;
  min-width: 200px;
  height: 100%;
}

.swipe-up-container {
  left: 0;
  right: 0;
  bottom: 1%;
  margin: auto;
  width: 100%;
  cursor: pointer;
}

.swipe-up {
  height: 40px !important;
}

.swipe-up-container h3 {
  margin: 10px;
  text-align: center;
  color: #00539C;
}

.title {
  margin-top: 2%;
  text-align: center;
  font-size: 1.5rem;
  color: grey;
  margin-bottom: 0px;
}

.content-container {
  margin: 1% 5%;
}

.progress-bar-container {
  padding: 0;
  padding-top: 5px;
}

.progress-bar {
  animation: scale-width 0.75s cubic-bezier(0, 0, 0.35, 1);
}

.project-container {
  border-radius: 20px;
  transition: 0.6s;
  padding: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
  margin: 25px 0;
  animation: fade-in ease 1s;
}

.project-description-container h3 {
  color: black;
  font-weight: bold;
  margin: 0px;
}

.project-description-container p {
  font-size: medium;
  margin: 20px 0;
  width: 75%;
  float: left;
  margin-right: 10px;
  color: grey;
}

.project-container img {
  border-radius: 18px;
  float: right;
  width: 15%;
  max-width: 100px;
  min-width: 50px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.project-labels {
  clear: both;
}

.project-label {
  display: inline-block;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 6px 10px;
  border-radius: 20px;
  color: white;
  font-size: 0.8rem;
  clear: both;
}

.project-container:hover {
  background-color: #E7E7E7;
}

@keyframes fade-in {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

@keyframes scale-width {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}


@media only screen and (min-width: 1200px) {

  body {
    margin-left: 3rem;
    margin-right: 3rem;  
  }

  .project-container:nth-child(even) {
    float: right;
  }

  .project-container:nth-child(odd) {
    float: left;
  }


  .project-container {
    width: 40%;
    height: 200px;
  }

  .project-description-container p {
    height: 9npm 0px;
  }

  .swipe-up-container {
    width: 20%;
  }

}